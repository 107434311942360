<template>
  <section class="relative">
    <v-parallax class="parallax" src="../assets/background-banner2.jpg">
      <div class="parallax-content">
        <router-link to="/" class="titleLink"><h1 class="parallax-content-title">DEADBANGERS</h1></router-link>
        <div class="parallax-content-subtitle">
          <span class="subtitle-space">Heavy Metal 🤘</span> |
          <span class="subtitle-space">Paris 📍</span> |
          <span class="subtitle-space">Beer Lovers 🍺</span>
        </div>
        <div class="social">
          <a href="https://www.facebook.com/deadbangersofficial" target="_blank" class="mx-3"><i class="fab fa-facebook white--text"></i></a>
          <a href="https://www.instagram.com/deadbangersband/" target="_blank" class="mx-3"><i class="fab fa-instagram white--text"></i></a>
          <a href="https://www.youtube.com/channel/UCJSsdmGcsDXKv2udJT_UUCA" target="_blank" class="mx-3"><i class="fab fa-youtube white--text"></i></a>
          <a href="https://yanistam.me" target="_blank" class="mx-3"><i class="fab fa-spotify white--text"></i></a>
          <a href="https://soundcloud.com/deadbangers-official" target="_blank" class="mx-3"><i class="fab fa-soundcloud white--text"></i></a>
          <a href="https://deadbangersparis.bandcamp.com/" target="_blank" class="mx-3"><i class="fab fa-bandcamp white--text"></i></a>
        </div>
      </div>
    </v-parallax>
  </section>
</template>

<script>
export default {
  name: 'Banner',
}
</script>

<style scoped>
  .titleLink {
    text-decoration: none;
    color: white;
  }

  .parallax > :nth-child(2) {
    padding: 0!important;
  }

  .parallax-content {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .parallax-content-title {
    font-size: 56px;
  }

  .parallax-content-subtitle {
    font-size:18px;
    font-weight: 500;
  }

  .subtitle-space {
    padding: 0 8px;
  }

  .social {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .social a{
    margin-top: 48px;
    font-size: 36px;
    padding: 0 16px;
  }

  .social a:hover i {
    color: #343434!important;
    transition: 0.2s;
  }

  @media (max-width: 992px) {
    .parallax {
      height: 400px!important;
    }

    .parallax-content-title {
      font-size: 32px;
    }

    .parallax-content-subtitle {
      font-size:12px;
      font-weight: 500;
    }

    .social {
      flex-wrap: wrap;
      margin-top: 32px;
    }

    .social a {
      margin-top: 12px;
      font-size: 28px;
    }
  }

  @media (min-width: 1400px) {
    .parallax {
      height: 600px!important;
    }
  }
</style>
