import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import NewsPage from '../views/NewsPage.vue'
import MembersPage from '../views/MembersPage.vue'
import MusicPage from '../views/MusicPage.vue'
import MediaPage from '../views/MediaPage.vue'
import InfluencesPage from '../views/InfluencesPage.vue'
import ContactPage from '../views/ContactPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/news',
    name: 'NewsPage',
    component: NewsPage
  },
  {
    path: '/members',
    name: 'MembersPage',
    component: MembersPage
  },
  {
    path: '/music',
    name: 'MusicPage',
    component: MusicPage
  },
  {
    path: '/media',
    name: 'MediaPage',
    component: MediaPage
  },
  {
    path: '/influences',
    name: 'InfluencesPage',
    component: InfluencesPage
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
