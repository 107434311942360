<template>
    <div class="navbar">
      <div class="desktop-navbar navbar-container container">
        <router-link to="/" class="navbar-link">Home</router-link>
        <router-link to="/news" class="navbar-link">News</router-link>
        <router-link to="/members" class="navbar-link">Members</router-link>
        <router-link to="/music" class="navbar-link">Music</router-link>
        <router-link to="/media" class="navbar-link">Media</router-link>
        <router-link to="/influences" class="navbar-link">Influences</router-link>
        <router-link to="/contact" class="navbar-link">Contact</router-link>
      </div>
      <div class="mobile-navbar">
        <i class="fas fa-bars white--text mobile-navbar-button" @click.stop="drawer = !drawer"></i>
        <v-navigation-drawer
          v-model="drawer"
          absolute
          left
          temporary
          class="mobile-navbar-burger"
        >
          <v-list nav dense>
            <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
            <router-link to="/" class="navbar-link">
              <v-list-item>
                <v-list-item-title><p class="navbar-link">Home</p></v-list-item-title>
              </v-list-item>
            </router-link>

              <router-link to="/news" class="navbar-link">
                <v-list-item>
                  <v-list-item-title><p class="navbar-link">News</p></v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/members" class="navbar-link">
                <v-list-item>
                  <v-list-item-title><p class="navbar-link">Members</p></v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/music" class="navbar-link">
                <v-list-item>
                  <v-list-item-title><p class="navbar-link">Music</p></v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/media" class="navbar-link">
                <v-list-item>
                  <v-list-item-title><p class="navbar-link">Media</p></v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/influences" class="navbar-link">
                <v-list-item>
                  <v-list-item-title><p class="navbar-link">Influences</p></v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/contact" class="navbar-link">
                <v-list-item>
                  <v-list-item-title><p class="navbar-link">Contact</p></v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Navbar',
  data: () => ({
    drawer: false,
    group: null,
  }),
  watch: {
    group () {
      this.drawer = false
    },
  },
}
</script>

<style scoped>
  .navbar {
    background: #272727;
    position: sticky;
    top: 0;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  }

  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .navbar-link {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    text-decoration: none!important;
    font-weight: 800;
    font-size: 18px;
    margin: 0;
  }

  .desktop-navbar .navbar-link:hover {
    color: black;
    transition: 0.2s;
  }

  .content {
    margin-top: 32px;
  }

  .mobile-navbar {
    position: absolute;
    top: -370px;
    left: 24px;
    color: white;
  }

  .mobile-navbar-button {
    font-size: 32px;
  }

  .mobile-navbar-burger {
    background: #272727!important;
    max-width: initial!important;
    width: 80vw!important;
    height: 100vh!important;
    top: -30px!important;
    left: -30px!important;
  }

  @media (max-width: 992px) {
    .desktop-navbar {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .mobile-navbar {
      display: none;
    }
  }
</style>
