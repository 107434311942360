<template>
  <div>
    <p>NEWS</p>
  </div>
</template>

<script>
export default {
  name: 'NewsPage',
}
</script>

<style scoped>

</style>
