<template>
  <v-app>
    <v-main>
      <Banner />
      <section class="content">
        <Navbar />
        <div class="container">
          <transition>
            <router-view/>
          </transition>
        </div>
      </section>
    </v-main>
  </v-app>
</template>

<script>
import Banner from './components/Banner.vue';
import Navbar from './components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Banner,
    Navbar
  },
};
</script>

<style>
  #app {
    font-family: 'Raleway', sans-serif!important;
    background: #343434;
  }

  .content {
    position: relative;
  }
</style>
